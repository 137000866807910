import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const [, page, pageId] = pathname.split('/');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, pageId]);

  return null;
}
