import SecureLS from 'secure-ls';
import { getToken } from 'contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { AuthUrls } from 'services/api/urls';

const ls = new SecureLS({ encodingType: 'aes' });

class BaseService {
  static getHeaders = (isMultipart?: boolean) => {
    const headers = new Headers();
    if (!isMultipart) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('Accept', 'application/json');
    const lang = ls.get('lang') || 'fr';
    headers.append('Accept-Language', lang);
    return headers;
  };

  static logoutUser = () => {
    const event = new CustomEvent('onTokenExpired', {
      bubbles: true,
      cancelable: true,
    });
    window.dispatchEvent(event);
  };

  static renewToken = () => {
    const pending = localStorage.getItem('renew-token-status');
    if (pending) {
      return;
    }
    localStorage.setItem('renew-token-status', 'true');
    BaseService.getRequest(AuthUrls.REFRESH_TOKEN, true)
      .then(async (res: any) => {
        if (res.status === 200 || res.status === 201) {
          const data = await res.json();
          ls.set('token', data.jwt);
        }
      })
      .catch((e) => {
        console.warn('BaseService error', e);
      })
      .finally(() => {
        localStorage.removeItem('renew-token-status');
      });
  };

  static checkToken = () => {
    const token = getToken();
    const decoded = jwtDecode(token);
    const renewDelay = 900; // last 15 minutes
    // const renewDelay = 3540; // first minute for test
    const diff = (decoded.exp || 0) - Date.now() / 1000;

    if (!token || diff < 0) {
      // on met un timer pour être sur l'événement soit bien pris en compte par le composant React
      setTimeout(() => {
        BaseService.logoutUser();
      }, 2000);
    } else if (diff < renewDelay) {
      BaseService.renewToken();
    }
  };

  static getHeadersAuth = (isMultipart?: boolean) => {
    const headers = BaseService.getHeaders(isMultipart);
    const token = getToken();
    BaseService.checkToken();
    headers.append('Authorization', `Bearer ${token}`);
    return headers;
  };

  static request = async (
    url: string,
    method: string,
    requiredAuth?: boolean,
    body?: any,
    isMultipart?: boolean,
  ) => {
    const headers = requiredAuth
      ? BaseService.getHeadersAuth(isMultipart)
      : BaseService.getHeaders(isMultipart);

    try {
      return await fetch(url, {
        method,
        headers,
        mode: 'cors' as const,
        cache: 'default' as const,
        ...(body
          ? isMultipart
            ? { body }
            : { body: JSON.stringify(body) }
          : {}),
      });
    } catch (err: any) {
      return err;
    }
  };

  static postRequest = async (
    url: string,
    body: object,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'POST', requiredAuth, body);
  };

  static postFileRequest = async (
    url: string,
    body: FormData,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'POST', requiredAuth, body, true);
  };

  static putFileRequest = async (
    url: string,
    body: FormData,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'PUT', requiredAuth, body, true);
  };

  static putRequest = async (
    url: string,
    body: object,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'PUT', requiredAuth, body);
  };

  static patchRequest = async (
    url: string,
    body: object,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'PATCH', requiredAuth, body);
  };

  static deleteRequest = async (url: string, requiredAuth?: boolean) => {
    return BaseService.request(url, 'DELETE', requiredAuth);
  };

  static getRequest = async (url: string, requiredAuth?: boolean) => {
    return BaseService.request(url, 'GET', requiredAuth);
  };
}

export interface requestInterface {
  url?: string;
  body?: object;
  requiredAuth?: boolean;
}

export default BaseService;
