import React from 'react';
import { Spin, Layout } from 'antd';
const { Content } = Layout;

export default function LoadingScreen() {
  return (
    <Layout className="min-h-screen">
      <Content className="flex align-center">
        <Spin size={'large'} className="m-auto" />
      </Content>
    </Layout>
  );
}
