import React from 'react';
import clsx from 'clsx';

interface StatutProps {
  status?: string;
  color: string;
  full?: boolean;
}

const StatusTag = (props: StatutProps) => {
  const { status, color, full } = props;
  return status !== undefined ? (
    <div
      className={clsx(
        'inline-block py-2 px-5 rounded-md -my-2 text-center text-white',
        {
          'w-full': full,
        },
      )}
      style={{ backgroundColor: color }}
    >
      {status}
    </div>
  ) : null;
};

export default StatusTag;
