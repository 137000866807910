import Colors from 'const/colors';
import { DossierStatus } from 'types/Dossier';

const jaune = Colors.GrandPublic;
const marron = Colors.GrandPublic;
const orange = Colors.GrandPublic;
const rouge = Colors.Collectivites;
const vert = Colors.Agriculture;
const gris = Colors.Industriels;

export const getDossierStatusColor = (status: DossierStatus): string => {
  // Référence couleurs: https://docs.google.com/spreadsheets/d/11_BRm3rdT6hP_KuUXPs7yLJDyG4Xz65vg1oH1sSk6ig/edit?gid=0#gid=0
  switch (status) {
    case DossierStatus['Etude en cours']:
    case DossierStatus['Offre émise']:
    case DossierStatus['Chantier en cours']:
    case DossierStatus['Documents en attente']:
      return jaune;

    case DossierStatus['Dossier reçu']:
      return marron;

    case DossierStatus['En attente de passage bureau de contrôle']:
      return orange;

    case DossierStatus['Dossier incomplet']:
      return rouge;

    case DossierStatus['Dossier validé']:
    case DossierStatus['Prime Pro payée']:
    case DossierStatus['Part Bénéficiaire payée']:
    case DossierStatus['Prime Globale Payée']:
      return vert;

    case DossierStatus['Refusé']:
    case DossierStatus.Abandon:
      return gris;
    case DossierStatus["Demande d'informations"]:
      return jaune;

    case DossierStatus['Dossier en cours de validation']:
      return orange;

    default:
      return gris;
  }
};
