import Colors from 'const/colors';
import { OperationStatus } from 'types/Operation';

const orange = Colors.GrandPublic;
const red = Colors.Collectivites;
const green = Colors.Agriculture;
const gray = Colors.Industriels;

export const getDossierOperationStatusColor = (
  status: OperationStatus,
): string => {
  switch (status) {
    case OperationStatus.Litige:
    case OperationStatus.Incomplet:
      return red;
    case OperationStatus['Refusé']:
      return gray;
    case OperationStatus['Bon pour Prime']:
      return green;
    case OperationStatus['Remis au Contrôle']:
    case OperationStatus['Dossier en cours de validation']:
    case OperationStatus['Pré validé']:
    case OperationStatus['Action en cours']:
    case OperationStatus['Non conforme (Qualité)']:
    default:
      return orange;
  }
};
