// Taille maximum autorisée pour les fichiers joints, en Mo
export const MAX_UPLOAD_FILESIZE = +(process.env.MAX_ATTACHMENT_FILESIZE || 25);

// Types MIME de fichiers autorisés pour les fichiers joints
export const ALLOWED_FILE_TYPES = [
  'text/plain', // Fichiers texte
  'image/jpeg', // Images JPEG
  'image/png', // Images PNG
  'application/pdf', // Fichiers .pdf
  'application/msword', // Microsoft Word .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word (OpenXML) .docx
];
