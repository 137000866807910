import React from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

interface FrameProps {
  title?: string;
  children: any;
}

const Frame = (props: FrameProps) => {
  const { children, title } = props;
  return (
    <div className="w-full p-4 border-2 rounded border-solid border-Bleu-hellio shadow-md">
      {title && (
        <Title level={3} className="!text-Bleu-hellio">
          {title}
        </Title>
      )}
      {children}
    </div>
  );
};

export default Frame;
