import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Divider, Flex, Popover, Spin, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg';
import { useSFUserNotifications } from 'views/auth/hook/auth_hook';

export const NotificationPopover = () => {
  const { t } = useTranslation('notification');
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useSFUserNotifications();
  const hasNotifications = data ? data.notifications.length > 0 : false;
  const notificationsCount = data ? data.notifications.length : 0;

  const handleMarkNotificationHasRead = () => {
    // mutate notification
  };

  return (
    <Popover
      onOpenChange={(visible) => setOpen(visible)}
      open={open}
      rootClassName="no-padding-popover white-popover"
      content={
        <div className="w-96">
          <div className="flex justify-between items-center pl-6 pr-3 py-3">
            <Typography.Paragraph className="!mb-0 font-bold">
              {t('title')}
            </Typography.Paragraph>
            <Button
              type={'text'}
              icon={<CloseOutlined />}
              onClick={() => setOpen(false)}
            />
          </div>

          <Divider className="m-0" />

          <div className="py-4 px-6">
            {isLoading && (
              <Flex align="center" justify="center">
                <Spin />
              </Flex>
            )}

            {!isLoading && !hasNotifications && (
              <Typography.Paragraph className="!mb-0">
                {t('no_notification')}
              </Typography.Paragraph>
            )}

            {data &&
              hasNotifications &&
              data.notifications.map(
                (n: { message: string }, index: number) => {
                  return (
                    <Typography.Link
                      className="!flex !mb-2 px-3 py-2 rounded-md w-full !text-gray-900 hover:bg-gray-50"
                      key={index}
                    >
                      {n.message}
                    </Typography.Link>
                  );
                },
              )}
          </div>

          {hasNotifications && (
            <Fragment>
              <Divider className="m-0" />
              <Flex vertical align="flex-end">
                <Button
                  className="mx-6 my-4"
                  onClick={handleMarkNotificationHasRead}
                >
                  {t('mark_as_read')}
                </Button>
              </Flex>
            </Fragment>
          )}
        </div>
      }
      placement="bottom"
    >
      {hasNotifications ? (
        <Badge
          className="flex h-full w-full text-white"
          count={notificationsCount}
          size="small"
        >
          <BellIcon height={24} width={24} />
        </Badge>
      ) : (
        <BellIcon />
      )}
    </Popover>
  );
};
