import { useTranslation } from 'react-i18next';
import { filesize } from 'filesize';

export default function useFilesize() {
  const { t } = useTranslation();
  const symbols = t('filesize:symbols:jedec:bytes', {
    returnObjects: true,
  });
  const locale = t('translation:locale');

  return (size: number) =>
    filesize(size, { locale, standard: 'jedec', symbols });
}
