import React, { useState, useCallback, useMemo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { App } from 'antd';
import BaseService from 'services/api/BaseService';
import { AttachmentsUrls } from 'services/api/urls';
import type { UploadFile, UploadProps } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MAX_UPLOAD_FILESIZE, ALLOWED_FILE_TYPES } from 'const/attachments';

interface OnChangeOpts {
  file: UploadFile;
  fileList: UploadFile[];
}

export const useUploadAttachmentProps = (
  attachments: UploadFile[],
): UploadProps => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState(attachments);
  const { notification } = App.useApp();

  const deleteAttachment = useDeleteAttachment();
  const downloadAttachment = useDownloadAttachment();

  const displayError = useCallback(
    (file: UploadFile, message: ReactNode) => {
      notification.open({
        icon: <ExclamationCircleOutlined />,
        message: t('attachments:upload_error'),
        description: (
          <>
            {t('attachments:upload_error_description', {
              file: file.name,
            })}
            <br />
            {message}
          </>
        ),
      });
    },
    [notification, t],
  );

  const checkAndDownloadAttachment = useCallback(
    (file: UploadFile) => {
      if (file.url && file?.linkProps?.deleting !== 'true') {
        downloadAttachment(file);
      }
    },
    [downloadAttachment],
  );

  const replaceFile = useCallback(
    (file: UploadFile) => {
      const newFileList = fileList.map((existingFile) =>
        file.uid === existingFile.uid ? file : existingFile,
      );
      setFileList(newFileList);
    },
    [fileList],
  );

  const onChange = useCallback(
    ({ file, fileList: newFileList }: OnChangeOpts) => {
      // Réordonne la liste des fichiers pour mettre les plus récents en premier
      setFileList(
        newFileList.sort((fileA: UploadFile, fileB: UploadFile) =>
          `${fileB.lastModifiedDate}`.localeCompare(
            `${fileA.lastModifiedDate}`,
          ),
        ),
      );
      if (file.status === 'error') {
        displayError(file, file.response.message);
      } else if (file.status === 'done') {
        file.uid = file.response.id;
        file.url = '#'; // Définit un URL vide, le téléchargement étant géré de façon asynchrone
      }
    },
    [displayError],
  );

  const onRemove = useCallback(
    async (file: UploadFile) => {
      // Fichier déjà en cours de suppression
      if (file?.linkProps?.deleting === 'true') {
        return false;
      }
      // On ne peut supprimer que les fichiers téléchargeables (qui ont un URL)
      if (file.url) {
        const linkProps = file?.linkProps || {};
        replaceFile({
          ...file,
          linkProps: { ...linkProps, deleting: 'true' },
          url: undefined,
        });
        const result = await deleteAttachment(file);
        replaceFile({
          ...file,
          linkProps: { ...linkProps, deleting: 'false' },
          url: result ? undefined : '#',
        });
        return result;
      }
    },
    [deleteAttachment, replaceFile],
  );

  const beforeUpload = useCallback(
    (file: UploadFile) => {
      const errors = [];
      // Vérification du type
      if (!ALLOWED_FILE_TYPES.includes(file.type as string)) {
        errors.push(
          t('attachments:error_type', {
            type: file.type,
          }),
        );
      }

      // Vérification de la taille
      if (+(file.size || 0) > MAX_UPLOAD_FILESIZE * 1024 * 1024) {
        errors.push(
          t('attachments:error_size', {
            size: MAX_UPLOAD_FILESIZE,
          }),
        );
      }

      // Affichage et retour d'erreur
      if (errors.length) {
        displayError(
          file,
          <ul>
            {errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>,
        );
        file.status = 'error';
        return false;
      }

      return true;
    },
    [displayError, t],
  );

  return useMemo(
    () => ({
      headers: Object.fromEntries(
        Array.from(BaseService.getHeadersAuth(true).entries()),
      ),
      onRemove,
      onChange,
      onDownload: checkAndDownloadAttachment,
      onPreview: checkAndDownloadAttachment,
      beforeUpload,
      fileList,
    }),
    [beforeUpload, checkAndDownloadAttachment, fileList, onChange, onRemove],
  );
};

export const useDownloadAttachment = (): any => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const displayError = (message: string, fileName: string) =>
    notification.open({
      icon: <ExclamationCircleOutlined />,
      message: t('attachments:download_error'),
      description: (
        <>
          {t('attachments:download_error_description', {
            file: fileName,
          })}
          <br />
          {message}
        </>
      ),
    });

  return async (attachment: UploadFile) => {
    try {
      const response = await BaseService.getRequest(
        AttachmentsUrls.GET_DOWNLOAD(attachment.uid),
        true,
      );
      const data = await response.json();
      if ([200, 201].includes(response.status)) {
        const link = document.createElement('a');
        link.href = AttachmentsUrls.DOWNLOAD_ATTACHMENT(data.token);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      } else {
        displayError(data?.message, attachment.name);
      }
    } catch (e: any) {
      console.warn('Delete attachment error', e);
      displayError(
        e.message || t('global:internet_connexion_error'),
        attachment.name,
      );
    }
  };
};

export const useDeleteAttachment = (): any => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const displayError = (message: string, fileName: string) =>
    notification.open({
      icon: <ExclamationCircleOutlined />,
      message: t('attachments:delete_error'),
      description: (
        <>
          {t('attachments:delete_error_description', {
            file: fileName,
          })}
          <br />
          {message}
        </>
      ),
    });

  return async (attachment: UploadFile) => {
    try {
      const response = await BaseService.deleteRequest(
        AttachmentsUrls.DELETE(attachment.uid),
        true,
      );
      if ([200, 201].includes(response.status)) {
        return true;
      }
      const data = await response.json();
      displayError(data?.message, attachment.name);
    } catch (e: any) {
      console.warn('Delete attachment error', e);
      displayError(
        e.message || t('global:internet_connexion_error'),
        attachment.name,
      );
    }
    return false;
  };
};
