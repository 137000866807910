export enum DossierStatus {
  'Etude en cours',
  'Offre émise',
  'Chantier en cours',
  'Documents en attente',
  'Dossier reçu',
  'En attente de passage bureau de contrôle',
  'Dossier incomplet',
  'Dossier validé',
  'Prime Pro payée',
  'Part Bénéficiaire payée',
  'Prime Globale Payée',
  'Refusé',
  'Abandon',
  "Demande d'informations",
  'Dossier en cours de validation',
}

export interface CreatedBy {
  LastName: string;
  FirstName: string;
  Email: string;
}

export interface File {
  name: string;
  content: string;
}

export interface Lead {
  Company: string;
  Description: string;
  Nom_du_Site_Chantier__c: string;
  Adresse_du_chantier__c: string;
  Code_Postal_du_chantier__c: string;
  Ville_du_chantier__c: string;
  Date_previsionnelle_debut__c: string;
  Date_fin_travaux__c: string;
  Le_logement_a_plus_de_2_ans__c: boolean;
  Nature_des_travaux__c: string;
  Secteur__c: string;
  Type_d_operation_GC__c: string;
  Surface_tot_ventilee_partie_rehabilitee__c?: number;
  Ancienne_energie_de_chauffage_batiment__c?: string;
  Nouvelle_energie_de_chauffage_batiment__c?: string;
  Surface_chauffee__c?: number;
  Surface_chauffee_partie_rehabilitee__c?: number;
  Salutation: string;
  Lastname: string;
  Firstname: string;
  Email: string;
  Phone: string;
  Mod_le_d_incitation_financi_re_souhait__c: string;
  Nom_ETx__c?: string;
  Adresse_de_l_Entreprise__c?: string;
  Code_Postal_de_l_Entreprise__c?: string;
  Ville_ETx__c?: string;
  T_l_phone_de_l_entreprise__c?: string;
  Email_de_l_entreprise__c?: string;
}

export interface CreateDossierPayload {
  CreatedBy: CreatedBy;
  Lead: Lead;
  Files: File[];
}

export interface DossierHistoryItem {
  date: string;
  value: DossierStatus;
}

export interface Chantier {
  reference?: string;
  montant?: string;
  nomSite?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
  raisonSociale?: string;
  siret?: string;
}

export interface Beneficiaire {
  raisonSociale?: string;
  siren?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
  nom?: string;
  prenom?: string;
  email?: string;
  telephone?: string;
}

export interface ReferentCommercial {
  name?: string;
  email?: string;
}

export interface DossierDetails {
  id: string;
  statut: DossierStatus;
  chantier?: Chantier;
  beneficiaire?: Beneficiaire;
  referentCommercial?: ReferentCommercial;
  history: DossierHistoryItem[];
}
