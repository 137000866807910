import { Attachment } from 'types/Attachment';

export enum OperationStatus {
  'Éligible',
  'Éligibilité à confirmer',
  'Eligibilité non confirmée à ce stade',
  'Recommandations',
  'Annulé',
  'Dossier en cours de validation',
  'Incomplet',
  'Remis au Contrôle',
  'Pré validé',
  'Bon pour Prime',
  'Non conforme (Qualité)',
  'Action en cours',
  'Litige',
  'Refusé',
  'Retiré du dépôt',
}

export interface OperationHistoryItem {
  date: string;
  value: OperationStatus;
}

export interface Operation {
  id: string;
  libelle?: string;
  fost?: string;
  montantPrime?: number;
  statut?: OperationStatus;
}

export interface OperationFull extends Operation {
  criteresEligibilite?: string;
  mentionsFacture?: string;
}

export interface OperationDetails {
  operation: OperationFull;
  history: OperationHistoryItem[];
  attachments: Attachment[];
}

export interface Operations {
  records: OperationDetails[];
  totalSize: number;
}

export enum SecteurOperations {
  INDUSTRIE = 'Industrie',
  RESEAUX = 'Réseaux',
  TERTIAIRE = 'Tertiaire',
  AGRICULTURE = 'Agriculture',
  RESIDENTIEL = 'Résidentiel',
}
