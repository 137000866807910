import React from 'react';
import { StatusTag } from 'components';
import { getDossierOperationStatusColor } from 'const/statutsDossierOperation';
import { OperationStatus } from 'types/Operation';

interface DossierStatutProps {
  status?: OperationStatus;
  full?: boolean;
}

const OperationStatusTag = (props: DossierStatutProps) => {
  const { full, status } = props;
  if (status === undefined) {
    return null;
  }
  const statusValue = OperationStatus[status];
  return (
    <StatusTag
      full={full}
      status={statusValue}
      color={getDossierOperationStatusColor(status)}
    />
  );
};

export default OperationStatusTag;
