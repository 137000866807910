import React from 'react';
import { StatusTag } from 'components';
import { getDossierStatusColor } from 'const/statutsDossier';
import { DossierStatus } from 'types/Dossier';

interface DossierStatutProps {
  status?: DossierStatus;
  full?: boolean;
}

const DossierStatusTag = (props: DossierStatutProps) => {
  const { full, status } = props;
  if (status === undefined) {
    return null;
  }
  const statusValue = DossierStatus[status];
  return (
    <StatusTag
      full={full}
      status={statusValue}
      color={getDossierStatusColor(status)}
    />
  );
};

export default DossierStatusTag;
