import {
  Avatar,
  Flex,
  Layout,
  Menu,
  Row,
  Col,
  notification,
  theme,
  Typography,
  Button,
  Spin,
} from 'antd';
import clsx from 'clsx';
import { ReactComponent as WhiteLogo } from 'assets/icons/WhiteLogo.svg';
import { ReactComponent as GreenPlantDecor } from 'assets/decor/GreenPlant.svg';
import { ReactComponent as GrayLeavesDecor } from 'assets/decor/GrayLeaves.svg';
import { useAuthContext } from 'contexts/AuthContext';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory } from 'react-router';
import {
  MenuItem,
  topNavigationItems,
  bottomNavigationItems,
} from 'routes/navigationConfig';
import BaseService from 'services/api/BaseService';
const { Header, Content, Footer } = Layout;

function getNavigationKeys(items: Array<any>): Array<string> {
  return (
    items?.reduce(
      (otherItems: Array<any>, item: any) => [
        ...otherItems,
        item.key,
        ...getNavigationKeys(item?.children),
      ],
      [],
    ) || []
  );
}

const FooterList = (props: { items: Array<React.ReactNode> }) => {
  const { items } = props;
  return (
    <Col xs={24} md={12} lg={6}>
      <ul>
        {items.map((item: React.ReactNode, index) => (
          <li key={`item${index}`}>{item}</li>
        ))}
      </ul>
    </Col>
  );
};

const FooterLinkList = (props: { items: Array<any> }) => {
  const { items } = props;
  return (
    <FooterList
      items={items.map(({ key, label, target = '_self' }) => (
        <a key={key} href={key} target={target}>
          {label}
        </a>
      ))}
    />
  );
};

const AppLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { auth, logout, owner, ownerIsLoading } = useAuthContext();
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;

  // Coordonnées du propriétaire du contact
  const ownerEmail = owner?.records?.[0]?.Owner?.Email;
  const ownerTel = owner?.records?.[0]?.Owner?.MobilePhone;

  const displayName = `${auth?.user?.firstname} ${auth?.user?.lastname}`;
  const initials = displayName
    .replace(/[%s-]+/, ' ')
    .split(' ')
    .map((part) => part[0])
    .join('');

  const handleLogout = useCallback(() => {
    logout();
    history.push('/connexion');
  }, [history, logout]);

  const handleMenuClick = useCallback(
    (e: MenuItem) => {
      if (e?.key === 'logout') {
        handleLogout();
      } else if (e?.key === 'notifications') {
        // TODO: Ouvrir les notifications
      } else if (e?.key === 'contact') {
        // TODO: Ouvrir la tooltip de contact
      } else if (typeof e?.key === 'string') {
        history.push(e?.key);
      }
    },
    [history, handleLogout],
  );

  const visibilityChange = () => {
    if (document.visibilityState === 'visible' || !document.hidden) {
      BaseService.checkToken();
    }
  };

  const handleTokenExpired = useCallback(() => {
    handleLogout();
    notification.info({
      message: t('global:session_expired'),
    });
  }, [handleLogout, t]);

  useEffect(() => {
    window.addEventListener('onTokenExpired', handleTokenExpired);
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      window.removeEventListener('onTokenExpired', handleTokenExpired);
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, [handleTokenExpired]);

  const navMenuItems = useMemo(
    () =>
      topNavigationItems.map((item) => ({
        ...item,
        icon:
          (item.icon && (
            <Flex
              style={{ width: 20, height: 20, verticalAlign: 'middle' }}
              justify={'center'}
            >
              {item.icon}
            </Flex>
          )) ||
          (item.key === 'userAccount' && (
            <Avatar
              style={{
                width: 32,
                height: 32,
                margin: -6,
              }}
            >
              {initials}
            </Avatar>
          )),
        onClick: handleMenuClick,
      })),
    [handleMenuClick, initials],
  );

  const defaultSelectedKeys =
    getNavigationKeys(topNavigationItems).find((key) =>
      matchPath(pathname, {
        path: `${key}${key === '/' ? '' : '*'}`,
        exact: true,
      }),
    ) ?? '';

  const {
    token: {
      Layout: { maxWidth },
    },
  } = theme.useToken() as any;

  const isImpersonated = auth?.user?.impersonated;

  return (
    <Layout
      className="print:bg-white"
      style={{
        minHeight: '100vh',
      }}
    >
      {isImpersonated && (
        <div
          className={
            'print:hidden fixed top-0 left-0 right-0 bg-gray-800 flex items-center justify-center gap-6 h-[52px]'
          }
        >
          <Typography.Paragraph className={'!m-0 text-white'}>
            {t('login:impersonate', {
              name: displayName,
            })}
          </Typography.Paragraph>
          <Button onClick={handleLogout} type={'primary'}>
            {t('global:logout')}
          </Button>
        </div>
      )}
      <Header
        className={clsx('print:hidden flex ', {
          'top-[52px]': isImpersonated,
        })}
        style={{
          alignItems: 'center',
          position: 'fixed',
          width: '100%',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth,
            margin: 'auto',
          }}
        >
          <WhiteLogo
            style={{
              marginRight: '50px',
            }}
          />
          <Menu
            theme="dark"
            selectedKeys={[defaultSelectedKeys]}
            mode="horizontal"
            items={navMenuItems}
            style={{
              flex: 1,
              minWidth: 0,
              justifyContent: 'center',
              flexGrow: 1,
            }}
          />
        </div>
      </Header>
      <Content
        className={clsx(
          'flex print:p-0 px-[32px] pt-[32px] pb-[132px] print:mt-0 ',
          {
            'mt-[76px]': !isImpersonated,
            'mt-[128px]': isImpersonated,
          },
        )}
        style={{
          position: 'relative',
        }}
      >
        <Flex vertical className="grow w-full mx-auto" style={{ maxWidth }}>
          {children}
        </Flex>
      </Content>
      <Footer className="print:hidden">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth,
            margin: 'auto',
            position: 'relative',
          }}
        >
          <div
            className="flex"
            style={{
              position: 'absolute',
              top: '-123px',
              right: 0,
            }}
          >
            <GreenPlantDecor style={{ verticalAlign: 'middle' }} />
            <GrayLeavesDecor style={{ verticalAlign: 'middle' }} />
          </div>
          <Row justify="space-between" style={{ width: '100%' }} gutter={16}>
            <FooterList items={[]} />
            <FooterList
              items={[
                <>Contact&nbsp;:&nbsp;</>,
                ownerIsLoading && <Spin size={'small'} />,
                <>
                  <a href={`mailto:${ownerEmail}`}>{ownerEmail}</a>
                </>,
                <>
                  <a href={`tel:${ownerTel}`}>{ownerTel}</a>
                </>,
              ]}
            />
            <FooterLinkList
              items={bottomNavigationItems.slice(
                0,
                Math.ceil(bottomNavigationItems.length / 2),
              )}
            />
            <FooterLinkList
              items={bottomNavigationItems.slice(
                Math.ceil(bottomNavigationItems.length / 2),
              )}
            />
          </Row>
        </div>
      </Footer>
    </Layout>
  );
};

export default React.memo(AppLayout);
